import axios from 'axios'
import qs from 'qs'

const api = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

const USER_KEY = 'RS_USER_V1' + window.CONFIG.appId
const OPEN_ID_KEY = 'RS_OPEN_ID_KEY_V1' + window.CONFIG.appId

export const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem(USER_KEY))
  } catch (_) {
    return null
  }
}

export const getOpenId = () => {
  try {
    return localStorage.getItem(OPEN_ID_KEY)
  } catch (_) {
    return null
  }
}

export const setOpenId = openId => {
  localStorage.setItem(OPEN_ID_KEY, openId)
}

const basePost = (path, data) => {
  return api.post(path, qs.stringify(data)).then(res => {
    if (res.data.code === 200 && res.data.data) {
      return res.data.data
    } else {
      throw res.data.info
    }
  })
}

const post = (action, data) => {
  return basePost(window.CONFIG.SERVICE_API_URL, {
    action,
    token: getUser()?.id,
    data: JSON.stringify(data)
  })
}

const wxApiPost = (action, data) => {
  return basePost(window.CONFIG.WEIXIN_API_URL, {
    action,
    ...data
  })
}

export const login = params =>
  post('login', params).then(user => {
    localStorage.setItem(USER_KEY, JSON.stringify(user))
    return user
  })

export const register = params => post('register', params)

export const getDishesList = params => post('getdishesList', params)

export const makeOrder = params => post('AddOrder', params)
export const pay = params => post('jsapiScan', params)

export const getOrderList = params => post('GetOrderList', params)
export const getOrder = params => post('GetOrder', params)
export const updateOrder = params => post('UpdateWxOrder', params)

export const getOpenIdByCode = params => wxApiPost('getUserInfo', params)

export const getKouWei = params => post('GetKWList', params)

export const getReminder = () =>
  post('getParamList', {
    jg: '001',
    code: '001'
  }).then(JSON.parse)

export const refund = params => post('refund', params)
